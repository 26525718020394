import React from "react";
import { graphql } from "gatsby";
import { Wrapper } from "../components/site/Wrapper";
import { Image } from "../components/site/Image";
import { Button } from "../components/site/Button";

const Contact = (props) => {
  // console.log(props);
  const books = props?.data?.allBooksJson?.edges;

  return (
    <Wrapper>
      <div className="container gx-1">
        <h1 className="fw-bold">Contact Us</h1>
        <div className="my-3 mx-2 col-lg-8">
          <div>
            We value your feedback and suggestions, and we are always happy to
            hear from our customers. If you have any questions or concerns,
            please don't hesitate to reach out to us.
          </div>
          <button
              type="button"
              className="px-0 btn btn-link"
              data-bs-toggle="modal"
              data-bs-target="#contactModal"
            >
              Contact form
            </button>
          <div>
            In your correspondence please include your name, email address, and message, and one of us will get back to you as soon as possible.
          </div>
          <div>
            We strive to provide the best possible customer service and we will
            do everything we can to make sure your PuzzleBase Games experience
            is a positive one. Thank you for choosing PuzzleBase Games!
          </div>
          <button
            type="button"
            className="px-0 btn btn-link"
            data-bs-toggle="modal"
            data-bs-target="#contactModal"
          >
            <div className="">hello at puzzlebasegames.com</div>
          </button>
        </div>

        <div className="my-3 mx-2 col-lg-8">
          <div className="h3">Social Media</div>
          <div className="h4 my-2">Instagram</div>
          <div className="">
            <a href="https://www.instagram.com/puzzlebasegames">
              @puzzlebasegames
            </a>
          </div>

          <div className="h4 my-2">Twitter</div>
          <div className="">
            <a href="https://www.twitter.com/puzzlebasegames">
              @puzzlebasegames
            </a>
          </div>

          <div className="h4 my-2">Facebook</div>
          <div className="">
            <a href="https://www.facebook.com/puzzlebasegames">
              @puzzlebasegames
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export const query = graphql`
  # query will go here
  {
    allBooksJson {
      edges {
        node {
          title
          subTitle
          slug
          image
          amazonUrl
          amazonPrice
          shortUrl
          published
          pages
        }
      }
    }
  }
`;

export default Contact;
